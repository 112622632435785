import Vue from 'vue';

export default {
    find(parametros) {
        return Vue.axios.get("catalogo/puestos/find", { params: parametros }).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    get(id) {
        return Vue.axios.get("catalogo/puestos/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    update(parametros) {
        return Vue.axios.put("catalogo/puestos", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    delete(id) {
        return Vue.axios.delete("catalogo/puestos/" + id).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    store(parametros) {
        return Vue.axios.post("catalogo/puestos", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    duplicar(parametros) {
        return Vue.axios.post("catalogo/puestos/duplicar", parametros).then((response) => Promise.resolve(response.data)).catch((error) => Promise.reject(error));
    },
    updateMasivo(parametros) {
        return Vue.axios.post("catalogo/puestos/update-masivo", parametros).then((response) => Promise.resolve(response)).catch((error) => Promise.reject(error));
    },

}