<template>
    <div id="pageTable">   
        <v-overlay :value="loadingLottie"  :z-index="999" >
            <div class="align-center justify-center text-center">
                <v-progress-circular
                    indeterminate
                    :size="100"
                    :width="7"
                    color="#2DB9D1"
                >
                    <v-img
                        src="/static/icon/favicon.ico"
                        :width="60"
                    ></v-img>
                </v-progress-circular>
                <p class="mt-5">{{fraseLoading}}</p>
            </div>
        </v-overlay>
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin' && rol != 'admin-empresa'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>     
        <v-container xl fluid v-else>
            <v-row>
                <v-overlay :value="overlay" >
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1">
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60">
                            </v-img>
                        </v-progress-circular>
                        <p class="mt-5">{{fraseOverlay}}</p>
                    </div>
                    
                </v-overlay>
                <v-col cols="12" xs="12" sm="12" md="12" class="center" style="overflow-x:auto;">
                    <div class="margen d-flex">
                        <hr class="vr"/>
                        <p class="tblName">Incremento salarial</p>
                    </div>
                    <v-card class="borde-card elevation-0" min-width="500px">
                        <v-card-text class="px-30px pt-30px" >
                            <ValidationObserver ref="formIncremento" v-slot="{ invalid, validated  }">
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" >
                                        <v-row>
                                            <v-col cols="12" xs="12" sm="12" md="12" lg="8" xl="6">
                                                <v-menu button rounded offset-y class="elevation-0 d-inline ml-2 mr-2 "> 
                                                    <template v-slot:activator="{on, attrs}">
                                                        <v-btn
                                                            color="#EFEFEF" 
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            right
                                                            rounded
                                                            width="100%"
                                                            class="btnTipoIncremento justify-space-between elevation-0"
                                                        >
                                                            {{tipoNombre}}
                                                            <!-- <v-icon class="">keyboard_arrow_down</v-icon> -->
                                                            <template  v-for="(item,valor) in attrs" >
                                                                <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#4F4F4F'>expand_less</v-icon>
                                                                <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#4F4F4F'>expand_more</v-icon>
                                                            </template>
                                                        </v-btn>
                                                    </template>

                                                    <v-list>
                                                        <v-list-item 
                                                            v-for="(item, index) in tiposIncrementos"
                                                            :key="index"
                                                            @click="changeItem(item)"
                                                        >   
                                                            <v-list-item-title>{{ item.nombre }}</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </v-col>
                                        </v-row>
                                        
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="d-flex justify-end align-center text-center">
                                        <v-btn
                                            rounded
                                            class="btnModulo btnGeneral elevation-0 px-6"
                                            @click="resetValues()"
                                            :disabled="disabledBorrar"
                                        >
                                            Borrar campos
                                        </v-btn>
                                        <v-menu offset-y content-class="menuClassAcciones">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    dark
                                                    class="btnAcciones pr-1 ml-3"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    elevation="0"
                                                >
                                                    Acciones 
                                                    <template  v-for="(item,valor) in attrs" >
                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#FFFFFF'>expand_less</v-icon>
                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#FFFFFF'>expand_more</v-icon>
                                                    </template>
                                                </v-btn>
                                                
                                            </template>
                                            <div class="btnAccionesContenedor pt-3">
                                                <div @click="descargarLayout()" class="btnAccionesItem py-2">
                                                    <span>Exportar layout de salarios</span>
                                                </div>
                                            </div>
                                            <div class="btnAccionesContenedor pb-3">
                                                <div @click="abrirModalLayout()" class="btnAccionesItem py-2">
                                                    <span>Importar layout de salarios</span>
                                                </div>
                                            </div>
                                        </v-menu>
                                        
                                    </v-col> 
                                    <v-col v-if="rol == 'root'" cols="12" xs="12" sm="6" md="4" lg="4" xl="3" class=" pb-0 ma-0">
                                        <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Clientes"
                                                :items="clientes"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="cliente_value"
                                                class="pa-0 ma-0"
                                                required 
                                                :error-messages="errors"
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin'" cols="12" xs="12" sm="6" md="4" lg="4" xl="3" class=" pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="registrosPatronales"
                                            item_text="nombreClave"
                                            item_value="id"
                                            @dataMultiple="(data)=> registrosPatronalesSeleccionados = data"
                                            label="Registros patronales"
                                            ref="multRegistroPatronal"
                                        />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin'" cols="12" xs="12" sm="6" md="4" lg="4" xl="3" class=" pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="empresas"
                                            item_text="nombre"
                                            @dataMultiple="(data)=> empresasSeleccionadas = data"
                                            label="Empresas"
                                            ref="multEmpresa"
                                        />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="6" md="4" lg="4" xl="3" class=" pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="sucursales"
                                            item_text="nombre"
                                            @dataMultiple="(data)=> sucursalesSeleccionadas = data"
                                            label="Sucursales"
                                            ref="multSucursal"
                                        />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="6" md="4" lg="4" xl="3" class=" pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="departamentos"
                                            item_text="nombre"
                                            @dataMultiple="(data)=> departamentosSeleccionados = data"
                                            label="Departamentos"
                                            ref="multDepartamento"
                                        />
                                    </v-col>
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="6" md="4" lg="4" xl="3" class=" pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="puestos"
                                            item_text="nombre"
                                            @dataMultiple="(data)=> puestosSeleccionados = data"
                                            label="Puestos"
                                            ref="multPuesto"
                                        />
                                    </v-col>
                                    

                                    <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="3">
                                        <VuetifyDatePicker
                                            outlined
                                            rules="required"
                                            :valorDefecto="fecha"
                                            v-model="fecha"
                                            label="Fecha de aplicación"
                                            placeholder="dd/mm/aaaa"
                                        />
                                    </v-col>
                                    
                                    <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="3">
                                        <ValidationProvider v-slot="{ errors }" name="Porcentaje" rules="required" v-if="tipoIncremento == 'incremento_general_porcentaje'">
                                            <v-text-field
                                                outlined
                                                label="Porcentaje"
                                                class="pa-0 ma-0"
                                                v-on:keypress="presicionNumero($event,valorIncremento)"
                                                oninput="if(this.value > 100 || this.value<0) this.value = 100; this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                v-model="valorIncremento" 
                                                suffix="%"
                                                :error-messages="errors" 
                                                required
                                            />
                                        </ValidationProvider>

                                        <VuetifyMoneyValidate
                                            v-if="tipoIncremento == 'incremento_general_cantidad' || tipoIncremento == 'salario_general'"
                                            outlined
                                            label="Importe"
                                            class="pa-0 ma-0"
                                            prefix="$"
                                            v-model="valorIncremento"
                                            v-bind:options="options"
                                            rules="required"
                                        >
                                        </VuetifyMoneyValidate>
                                    </v-col>


                                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="my-0 py-0">
                                        <p class="mb-2 informacionGeneralTitulo">Rango de salario diario</p>
                                        <v-radio-group v-model="opcion" row hideDetails>
                                            <v-radio class="mb-4 radio-label" label="Rango" value="rango"></v-radio>
                                            <v-radio class="mb-4 radio-label" label="Diferente a" value="diferente"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <template v-if="opcion == 'rango'">
                                        <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="3">
                                            <VuetifyMoneyValidate
                                                outlined
                                                label="Desde"
                                                class="pa-0 ma-0"
                                                prefix="$"
                                                v-model="valorSalarioMin"
                                                v-bind:options="options"
                                            >
                                            </VuetifyMoneyValidate>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="3">
                                            <VuetifyMoneyValidate
                                                outlined
                                                label="Hasta"
                                                class="pa-0 ma-0"
                                                prefix="$"
                                                v-model="valorSalarioMax"
                                                v-bind:options="options"
                                            >
                                            </VuetifyMoneyValidate>
                                        </v-col>
                                    </template>
                                    <template v-if="opcion == 'diferente'">
                                        <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="3">
                                            <VuetifyMoneyValidate
                                                outlined
                                                label="Diferente a"
                                                class="pa-0 ma-0"
                                                prefix="$"
                                                v-model="valorNoSalario"
                                                v-bind:options="options"
                                            >
                                            </VuetifyMoneyValidate>
                                        </v-col>
                                    </template>
                                    
                                    <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="3" class="py-0" :class="($vuetify.breakpoint.xs ||$vuetify.breakpoint.sm) ?' pt-n7 pb-8':'pt-5'">
                                        <v-btn
                                            class="btnGuardar ml-2"
                                            @click="getEmpleados(valorSalarioMin, valorSalarioMax, valorNoSalario)" 
                                        >
                                            Filtrar
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" class="my-0 py-0">
                                    </v-col>

                                    
                                </v-row>


                                <v-row class="d-flex justify-space-between">
                                    <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="6" md="4" lg="4" xl="3" class="pb-0 ma-0">
                                        <VuetifyMultipleAutocomplete
                                            outlined
                                            :items="empleados"
                                            item_text="nombre_completo"
                                            @dataMultiple="(data)=> empleadosSeleccionados = data"
                                            label="Empleados"
                                            ref="multEmpleados"
                                            :loading="loadingEmpleados"
                                        />
                                    </v-col>

                                    <v-col cols="12" xs="12" sm="6" md="4" lg="4" xl="3" class="pb-0 ma-0 d-flex justify-end pt-5">
                                        <v-btn
                                            rounded
                                            class="btnModulo btnGeneral elevation-0 px-6 ml-3"
                                            @click="procesar()"
                                            :disabled="!tipoIncremento"
                                            :loading="loadingProcesar"
                                        >
                                            Procesar
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </ValidationObserver>
                        </v-card-text>
                    </v-card>
                </v-col>


                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-card style="border-radius: 20px; background: transparent;" elevation="0">
                        <v-overlay :z-index="999" :value="overlayCargar" :absolute="true">
                            <div class="align-center justify-center text-center">
                                <v-progress-circular
                                    indeterminate
                                    :size="100"
                                    :width="7"
                                    color="#2DB9D1"
                                >
                                    <v-img
                                        src="/static/icon/favicon.ico"
                                        :width="60"
                                    ></v-img>
                                </v-progress-circular>
                                <p class="mt-5">Cargando...</p>
                            </div>
                        </v-overlay>
                        
                        <v-card-actions class="d-flex py-0 ma-0 pt-4 px-0 mr-7">
                            <v-row class="ma-0 pa-0">
                                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pa-0">
                                    <div class="margen mb-0 d-flex">
                                        <hr class="vr"/>
                                        <p class="tblName">Lista de modificaciones al salario</p>
                                    </div>
                                </v-col>
                                <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pa-0 d-flex justify-end align-center">
                                    <span class="exportar-en">
                                        Exportar en
                                    </span>
                                    <v-btn
                                        color="#FFF"
                                        class="elevation-0 pt-0 pb-0 pl-0 pr-0 ml-3"
                                        @click="reporteConsulta()"
                                        height="42"
                                        width="15"
                                        min-width="50px"
                                        style="border-radius: 15px; box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.08);"
                                        :disabled="datosTabla.length == 0" 

                                    >
                                        <img src="/static/icon/xls.png" style="width:25px"/>
                                    </v-btn>
                                    <v-btn
                                        class="btnModulo btnGeneral elevation-0 px-6"
                                        @click="guardarIncremento()" 
                                        :disabled="btnGuardar" 
                                        :loading="isSaving"
                                    >
                                        Guardar
                                    </v-btn>
                                </v-col>
                            </v-row>
                            
                        </v-card-actions>
                        <v-card-text class="px-0 pb-30px pt-0" style="position:relative; background: transparent;">
                            <v-row class="pa-0 ma-0 areaConsulta informacionGeneralConsulta" style="border-radius: 20px; background: transparent;">
                                <v-col 
                                    cols="12" xs="12" sm="12" md="12" 
                                    class="pa-0 " 
                                    style="min-height:500px;"
                                    
                                >
                                    <div style="overflow-x: auto;">
                                        <ValidationObserver ref="formTabla" v-slot="{ invalid }">

                                            <table class="tbl-incidencias" >
                                                <thead class="tbl-header">
                                                    <tr>
                                                        <th rowspan=2 class="pl-7 firstth">Nombre del empleado</th>
                                                        <th rowspan=2 class="thTitulos px-8">Fecha de aplicación</th>
                                                        <th colspan="4" class="thTitulos px-8 text-center custom-border" style="border-bottom:1px solid #E0E0E0">Salario actual</th>
                                                        <th colspan="4" class="thTitulos px-8 text-center" style="border-bottom:1px solid #E0E0E0">Salario nuevo</th>
                                                        <th rowspan=2 class="lastth thTitulos px-8 text-center">Opciones</th>

                                                    </tr>
                                                    <tr>
                                                        <th class="thCheck pl-8">Salario diario</th>
                                                        <th class="thCheck">SBC fijo</th>
                                                        <th class="thCheck">SBC variable</th>
                                                        <th class="thCheck custom-border">SBC</th>
                                                        <th class="thCheck pl-8">Salario diario</th>
                                                        <th class="thCheck">SBC fijo</th>
                                                        <th class="thCheck">SBC variable</th>
                                                        <th class="thCheck">SBC</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-if="datosTabla.length == 0" >
                                                        <td class="emptyTableComplementos" colspan="11">
                                                            No hay elementos para mostrar
                                                        </td>
                                                    </tr>
                                                    <template v-else>
                                                        <template  v-for="(empleado, index) in datosTabla" >
                                                            <tr class="trBody">
                                                                <td :class="empleado.color == false ? 'custom-row-false':'custom-row-true'">
                                                                    <span class="pl-7">
                                                                        {{empleado.nombre_completo}}
                                                                    </span>
                                                                </td>
                                                                <td class="px-8" :class="empleado.color == false ? 'custom-row-false':'custom-row-true'">
                                                                    {{dateFormat(empleado.fecha_de_aplicacion)}}
                                                                </td>
                                                                <td class="pl-8" :class="empleado.color == false ? 'custom-row-false':'custom-row-true'">
                                                                    {{formatNum(empleado.salario_anterior)}}
                                                                </td>
                                                                <td :class="empleado.color == false ? 'custom-row-false':'custom-row-true'">
                                                                    {{formatNum(empleado.anterior_sbc_fijo)}}
                                                                </td>
                                                                <td :class="empleado.color == false ? 'custom-row-false':'custom-row-true'">
                                                                    {{formatNum(empleado.anterior_sbc_variable)}}
                                                                </td>
                                                                <td :class="empleado.color == false ? 'custom-row-false':'custom-row-true'" class="custom-border">
                                                                    {{formatNum(empleado.sbc_anterior)}}
                                                                </td>
                                                                <td class="pl-8" :class="empleado.color == false ? 'custom-row-false':'custom-row-true'">
                                                                    {{formatNum(empleado.salario_nuevo)}}
                                                                </td>
                                                                <td :class="empleado.color == false ? 'custom-row-false':'custom-row-true'">
                                                                    {{formatNum(empleado.nuevo_sbc_fijo)}}
                                                                </td>
                                                                <td :class="empleado.color == false ? 'custom-row-false':'custom-row-true'">
                                                                    {{formatNum(empleado.nuevo_sbc_variable)}}
                                                                </td>
                                                                <td :class="empleado.color == false ? 'custom-row-false':'custom-row-true'">
                                                                    {{formatNum(empleado.sbc_nuevo)}}
                                                                </td>
                                                                <td class="text-center">
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="abrirModal(empleado, index)">
                                                                                <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>Editar</span>
                                                                    </v-tooltip>
                                                                    <v-tooltip bottom>
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-btn v-on="on" class="botonHover" icon fab dark small @click="eliminar(empleado, index)">
                                                                                <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                                                            </v-btn>
                                                                        </template>
                                                                        <span>Eliminar</span>
                                                                    </v-tooltip>
                                                                </td>
                                                            </tr>
                                                        </template>
                                                    </template>
                                                    
                                                    <tr v-if="datosTabla.length != 0" >
                                                        <td class="tdBlanco" colspan="11">
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </ValidationObserver>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="600px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="px-50px pt-50px pb-0">
                                <div>
                                    <h2 class="titleModal">{{ "Modificar salario individual nuevo" }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-show="isLoadingModal">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingModal" grid-list-md id="contenedor">
                                    <v-divider class="divider mt-5"></v-divider>
                                    <v-row>
                                        
                                        <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                            <v-text-field
                                                outlined
                                                label="Empleado"
                                                class="pa-0 ma-0"
                                                disabled
                                                v-model="colaborador.nombre_completo" 
                                            />
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                            <ValidationProvider v-slot="{ errors }" name="Tipo de incremento" rules="required">
                                                <v-autocomplete
                                                    outlined
                                                    label="Tipo de incremento"
                                                    :items="tiposIncrementos"
                                                    item-text="nombre"
                                                    item-value="val"
                                                    persistent-hint
                                                    v-model="colaborador.tipo"
                                                    class="pa-0 ma-0"
                                                    :error-messages="errors" 
                                                    required
                                                >
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                            <VuetifyDatePicker
                                                outlined
                                                rules="required"
                                                :valorDefecto="colaborador.fecha_de_aplicacion"
                                                v-model="colaborador.fecha_de_aplicacion"
                                                label="Fecha de aplicación"
                                                placeholder="dd/mm/aaaa"
                                            />
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <VuetifyMoneyValidate
                                                outlined
                                                label="Salario diario actual"
                                                class="pa-0 ma-0"
                                                prefix="$"
                                                v-model="colaborador.salario_anterior"
                                                v-bind:options="options"
                                                readonly
                                            />
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0" v-if="colaborador.tipo === 'porcentaje'">
                                            <ValidationProvider v-slot="{ errors }" name="Porcentaje a incrementar" rules="required">
                                                <v-text-field
                                                    outlined
                                                    label="Porcentaje a incrementar"
                                                    class="pa-0 ma-0"
                                                    v-on:keypress="presicionNumero($event,colaborador.valor)"
                                                    oninput="if(this.value > 100 || this.value<0) this.value = 100; this.value=this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                    v-model="colaborador.valor" 
                                                    suffix="%"
                                                    :error-messages="errors" 
                                                    required
                                                />
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0" v-if="colaborador.tipo == 'fijo' || colaborador.tipo == 'monto'">
                                            <VuetifyMoneyValidate
                                                outlined
                                                rules="required"
                                                label="Importe a incrementar"
                                                class="pa-0 ma-0"
                                                prefix="$"
                                                v-model="colaborador.valor"
                                                v-bind:options="options"
                                            />
                                        </v-col>


                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <VuetifyMoneyValidate
                                                outlined
                                                label="Salario diario nuevo"
                                                class="pa-0 ma-0"
                                                prefix="$"
                                                v-model="salarioNuevo"
                                                v-bind:options="options"
                                                readonly
                                            />
                                        </v-col>
                                        
                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <VuetifyMoneyValidate
                                                outlined
                                                label="SBC Fijo"
                                                rules="required"
                                                class="pa-0 ma-0"
                                                prefix="$"
                                                v-model="colaborador.nuevo_sbc_fijo"
                                                v-bind:options="options"
                                                @onBlur="calcularSBC('manual')"
                                            >
                                                <template v-slot:append-icon>
                                                    <v-tooltip top class="pb-7">
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                class="elevation-0 ma-0 pa-0 v-btn-hover"
                                                                color="transparent"
                                                                rounded
                                                                :ripple="false" 
                                                                x-small
                                                                @click="calcularSBC('automatico')"
                                                                :loading="isLoadingSBC"
                                                                v-on="on" 
                                                                slot="activator"
                                                            >
                                                                <v-icon color="#828282" class="tamIconoBoton" v-text="'$calculate_outline'">
                                                                </v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span class="textTooltip">
                                                            Calcular SBC Fijo sobre la prestación seleccionada.
                                                        </span>
                                                    </v-tooltip>
                                                    
                                                </template>
                                            </VuetifyMoneyValidate>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <VuetifyMoneyValidate
                                                outlined
                                                label="SBC Variable"
                                                rules="required"
                                                class="pa-0 ma-0"
                                                prefix="$"
                                                v-model="colaborador.nuevo_sbc_variable"
                                                v-bind:options="options"
                                                @onBlur="calcularSBC('manual')"
                                            />
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="6" md="6" class="pt-0 pb-0 ma-0">
                                            <VuetifyMoneyValidate
                                                outlined
                                                label="SBC Total (Tope 25 UMA)"
                                                class="pa-0 ma-0"
                                                prefix="$"
                                                v-model="colaborador.salario_base_cotizacion"
                                                v-bind:options="options"
                                                readonly
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSavingActualizar"
                                >
                                    Cancelar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="guardarActualizar()" 
                                    :disabled="invalid" 
                                    :loading="isSavingActualizar"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

            <template>
                <v-dialog v-model="dialogLayout" persistent max-width="500px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" style="display: none;" class="botonModal" id="btnModalLayout"></v-btn>
                    </template>
                    <v-card>
                        <ValidationObserver ref="formLayout" v-slot="{ invalid }">
                            <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                                <div class="headerModalStart">
                                    <h2 class="titleModal_700_18">{{ "Importar layout" }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-text>
                                <div id="padre" v-show="isLoadingLayout">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-show="!isLoadingLayout" grid-list-md id="contenedor">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" class="py-0">
                                            <ValidationProvider v-slot="{ errors }" name="Layout" rules="required">
                                                <v-file-input
                                                    outlined
                                                    label="Seleccionar el documento"
                                                    v-model="archivo"
                                                    accept=".xlsx"
                                                    required 
                                                    :error-messages="errors"
                                                    prepend-icon=""
                                                    append-icon="attach_file"
                                                    id="fileDocument"
                                                    @click:append="open"
                                                >
                                                </v-file-input>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button class="btnCerrar" @click="cerrarModal()" :disabled="isSavingLayout">Cerrar</button>
                                <v-btn class="btnGuardar" @click="importarLayout()" :disabled="invalid" :loading="isSavingLayout">Subir</v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>
        </v-container>
    </div>
</template>

<script>

//IMPORTS
import Vue from 'vue';
//APIS
import apiRegistroPatronal from "@/api/nominas/registroPatronal";
import clientesApi from "@/api/clientes";
import empresaApi from "@/api/empresas";
import sucursalApi from "@/api/sucursales";
import departamentoApi from "@/api/departamentos";
import apiPuestos from "@/api/nominas/puestos";
import apiEmpleados from '@/api/empleados';
import apiIncrementoSalario from '@/api/nominas/incrementoSalario'

//COMPONENTS

import Datatable from '@/components/datatable/Datatable';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import ExpandableItem from '@/components/ExpandableItemPayments.vue';
import VuetifyMultipleAutocomplete from '@/components/VuetifyMultipleAutocomplete.vue';
import VuetifyMoneyValidate from '@/components/VuetifyMoneyValidate.vue';

//PLUGINS

import Notify from '@/plugins/notify';
import GetUrlImg from "@/plugins/Urlimagen";

export default {
    components: {
        VuetifyMultipleAutocomplete,
        VuetifyDatePicker,
        VuetifyMoneyValidate,
        'data-table': Datatable,
        ExpandableItem,
    },
    data() {
        return {
            tiposIncrementos          : [
                {
                    nombre:'Incremento general en porcentaje',
                    valor:'incremento_general_porcentaje',
                    val:'porcentaje'
                },
                {
                    nombre:'Incremento general en cantidad',
                    valor:'incremento_general_cantidad',
                    val:'monto'
                },
                {
                    nombre:'Nuevo salario general',
                    valor:'salario_general',
                    val:'fijo'
                }
            ],
            tipoIncremento          : null,    
            datosLogin              : null,
            rol                     : null,
            cliente_value           : null,

            overlay                 : false,
            fraseOverlay            : "Descargando",

            tipoNombre                   : "Seleccionar tipo",

            clientes                            : [],
            registrosPatronales                 : [],
            empresas                            : [],
            sucursales                          : [],
            departamentos                       : [],
            puestos                             : [],
            empleados                           : [],
            registrosPatronalesSeleccionados    : [],
            empresasSeleccionadas               : [],
            sucursalesSeleccionadas             : [],
            departamentosSeleccionados          : [],
            puestosSeleccionados                : [],
            empleadosSeleccionados              : [],
            fecha                               : null,
            valorIncremento                     : null,
            overlayCargar                       : false,
            btnGuardar                          : false,
            isSaving                            : false,
            showDatos                           : false,
            loadingProcesar                     : false,
            datosTabla                          : [],

            dialog                              : false,
            isLoadingModal                      : false,
            isSavingActualizar                  : false,
            indexColaborador                    : null,
            colaborador                         : {
                nombre_completo         : null,
                empleado_id             : null,
                salario_anterior        : null,
                sbc_anterior            : null,
                salario_nuevo           : null,
                sbc_nuevo               : null,
                nombre_completo         : null,
                fecha_de_aplicacion     : null,
                tipo                    : null,
                valor                   : null,
                salario_base_cotizacion : null,
                nuevo_sbc_fijo          : null,
                nuevo_sbc_variable      : null,
            },
            
            options: {
                locale: "es-MX",
                prefix: "$",
                suffix: "",
                length: 11,
                precision: 2
            },
            parametrosReporte   : null,
            isLoadingSBC        : false,
            disabledBorrar      : false,
            loadingEmpleados    : false,
            valorSalarioMin     : null,
            valorSalarioMax     : null,
            valorNoSalario      : null,
            loadingLottie       : false,
            fraseLoading        : '',
            archivo             : null,
            dialogLayout        : false,
            isSavingLayout      : false,
            isLoadingLayout     : false,
            opcion              : 'rango',

        }
    },
    /* WATCH 
    Entrada: valor nuevo de la variable y valor pasado de la variable (newValue,oldValue)
    Salida:
    Descripcion: Las funciones de este tipo reciben el valor 
                 nuevo y pasado de una variable cuando detecta 
                 un cambio para posteriormente ejecutar alguna 
                 instruccion.*/
    watch: {
        /*Descripcion: Ejectuta cambiaCliente() mandando el nuevo valor de esta variable*/
        cliente_value: function(val) {
            this.cambiaCliente(val);
            this.borrarValores();
        },
        registrosPatronalesSeleccionados:{
            deep:true,  
            handler(val){
                this.getEmpleados();
                this.cambiaRegistroPatronal();
                this.borrarValores();
            }
        },
        /*Descripcion: Ejectuta cambiaEmpresa() mandando el nuevo valor de esta variable*/
        empresasSeleccionadas:{
            deep:true,  
            handler(val){
                this.cambiaEmpresas(val);
                this.getPuestos();
                this.borrarValores();
            }
        },
        /*Descripcion: Ejectuta cambiaScursal() mandando el nuevo valor de esta variable*/
        sucursalesSeleccionadas:{
            deep:true,  
            handler(val){
                this.cambiaSucursales(val);
                this.getPuestos();
                this.borrarValores();
            }
        },

        /*Descripcion: Ejectuta getEmpleados() */
        departamentosSeleccionados:{
            deep:true,  
            handler(val){
                this.getEmpleados();
                this.borrarValores();
            }
        },

        puestosSeleccionados:{
            deep:true,
            handler(val){
                this.getEmpleados();
                this.borrarValores();
            }
        },

        empleadosSeleccionados:{
            deep:true,
            handler(val){
                this.borrarValores();
            }
        },

        fecha:function(val){
            this.getEmpleados()
            this.borrarValores();
        },

        valorIncremento:function(val){
            this.borrarValores();
        },

        tipoIncremento: function(val) {
            this.valorIncremento = null;
        },

        'colaborador.tipo': function(val, oldVal){
            if(oldVal != null){
                this.colaborador.valor = null;
            }
        },

        opcion: function(val) {

            if(val == 'rango'){
                this.valorNoSalario     = null;
                this.getEmpleados(null, null)
            }
            else if(val == 'diferente'){
                this.valorSalarioMin    = null;
                this.valorSalarioMax    = null;
            }
        },
    },
    computed:{
        salarioNuevo: function(){
            let resultado = 0;

            if(this.colaborador.tipo == 'porcentaje'){
                resultado = ((parseFloat(this.colaborador.valor) / 100) * (parseFloat(this.colaborador.salario_anterior))) + (parseFloat(this.colaborador.salario_anterior)) ;
            }
            else if (this.colaborador.tipo == 'monto'){
                resultado = parseFloat(this.colaborador.valor) + parseFloat(this.colaborador.salario_anterior);
            }
            else if(this.colaborador.tipo == 'fijo'){
                resultado = parseFloat(this.colaborador.valor)
            }

            return resultado;
        },

        sbcTotal: function(){
                let sumaSBC = 0;

                sumaSBC =  ((this.colaborador.nuevo_sbc_fijo != null && this.colaborador.nuevo_sbc_fijo != "" && this.colaborador.nuevo_sbc_fijo != undefined) ? parseFloat(this.colaborador.nuevo_sbc_fijo) : 0) 
                        + 
                        ((this.colaborador.nuevo_sbc_variable != null && this.colaborador.nuevo_sbc_variable != "" && this.colaborador.nuevo_sbc_variable != undefined) ? parseFloat(this.colaborador.nuevo_sbc_variable) : 0 )
            
            return sumaSBC
        }
    },
    methods: {
        open(){
            //this.$refs.fileDocument.$el.querySelector('input').click()
            //this.$refs.fileDocument.click()
            document.getElementById("fileDocument").click();
        },
        borrarValores(){
            this.$nextTick(() => { 
                if (this.rol == "root") {
                   if(
                        this.cliente_value                              == null&&
                        this.registrosPatronalesSeleccionados.length    == 0 && 
                        this.empresasSeleccionadas.length               == 0 &&
                        this.sucursalesSeleccionadas.length             == 0 &&
                        this.departamentosSeleccionados.length          == 0 &&
                        this.puestosSeleccionados.length                == 0 &&
                        this.fecha                                      == null &&
                        this.valorIncremento                            == null &&
                        this.tipoNombre                                 == "Seleccionar tipo"
                    ){
                        this.disabledBorrar = true;
                    }
                    else{
                        this.disabledBorrar = false;
                    }
                }
    
                if (this.rol == "admin") {
                    if(
                        this.registrosPatronalesSeleccionados.length    == 0 && 
                        this.empresasSeleccionadas.length               == 0 &&
                        this.sucursalesSeleccionadas.length             == 0 &&
                        this.departamentosSeleccionados.length          == 0 &&
                        this.puestosSeleccionados.length                == 0 &&
                        this.fecha                                      == null &&
                        this.valorIncremento                            == null &&
                        this.tipoNombre                                 == "Seleccionar tipo"
                    ){                        
                        this.disabledBorrar = true;
                    }
                    else{
                        this.disabledBorrar = false;
                    }
                }

                if (this.rol == "admin-empresa") {
                    if(
                        this.sucursalesSeleccionadas.length             == 0 &&
                        this.departamentosSeleccionados.length          == 0 &&
                        this.puestosSeleccionados.length                == 0 &&
                        this.fecha                                      == null &&
                        this.valorIncremento                            == null &&
                        this.tipoNombre                                 == "Seleccionar tipo"
                    ){
                        this.disabledBorrar = true;
                    }
                    else{
                        this.disabledBorrar = false;
                    }
                } 
            });
        },

        /**
         * @method getEmpleados Este método realiza la petición a la API de empleados
         * @description Realiza petición a la API de empleados cuando la empresa es seleccionada y el resultado lo almacena en el array empleados[].
         * Si la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición.
        */
        getEmpleados(min_salario = null, max_salario= null, salario_diferente = null){
            if(this.fecha == null || this.fecha == ""){
                this.empleados = []
                this.empleadosSeleccionados = []
                return
            }

            if(this.registrosPatronalesSeleccionados.length == 0 ){
                this.empleados = []
                this.empleadosSeleccionados = []
                return
            }

            let parametros = { 
                paginate: false,
                activo: true,
                usuario_id : this.datosLogin.id,
                cliente_id: this.cliente_value, 
                rol_name: this.datosLogin.rol.name,
                arrayRegistroPatronal : this.registrosPatronalesSeleccionados.length == 0 ? null:JSON.stringify(this.registrosPatronalesSeleccionados), 
                arrayEmpresa : this.empresasSeleccionadas.length == 0 ? null:JSON.stringify(this.empresasSeleccionadas), 
                arraySucursal : this.sucursalesSeleccionadas.length == 0 ? null:JSON.stringify(this.sucursalesSeleccionadas), 
                arrayDepartamento : this.departamentosSeleccionados.length == 0 ? null:JSON.stringify(this.departamentosSeleccionados),
                arrayPuesto : this.puestosSeleccionados.length == 0 ? null:JSON.stringify(this.puestosSeleccionados), 
                min_salario:min_salario,
                max_salario:max_salario,
                salario_diferente: salario_diferente,
                fecha_de_aplicacion : this.fecha, 
            };

            this.loadingEmpleados = true;
            
            apiEmpleados.getEmpleados(parametros).then((response)=>{
                this.empleados = response.data;
                this.empleados.map(emp => emp.nombre_completo += " - "+emp.nombre_empresa + " - "+emp.nombre_sucursal + " - "+emp.nombre_departamento);
                this.loadingEmpleados = false;
            })
            .catch(error => {
                this.loadingEmpleados = false;
                 Notify.ErrorToast("No se pudo realizar la petición de empleados.");
            });
        },

        /**
         * @method init Este método se ejecuta para inicializar los datos al crear la vista.
         * @description Este método se ejecuta en el ciclo de vida created(), se rellenan valores antes de montar la vista.
        */
        init() {
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;

            this.urlImg = GetUrlImg.getURL();

            if (this.rol == "root") {
                this.getClientes();
            }
            else if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
            }
            else if (this.rol == "admin-empresa") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresasSeleccionadas.push(this.datosLogin.empresa_id);
            }

            if(this.rol == "admin-sucursal"){
                Notify.ErrorToast("Tu rol no tiene acceso a este módulo");
            }
        },


        /**
         * @method getClientes Este método realiza la petición a la API de clientes.
         * @description Este método se ejecuta al seleccionar al iniciar la vista. El resultado se almacena en el array clientes=[]
        */
        async getClientes() {
            let param = { activo: true, paginate: false };
            await clientesApi.getClientes(param).then(response => {
                    this.clientes = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes");
            });
        },


        /**
         * @method cambiaCliente Este método realiza la petición a la API de Registros patronales.
         * @description Este método se almacena en el array registrosPatronales=[]. Se ejecuta al seleccionar un cliente.
        */
        async cambiaCliente(cliente) {
            if(cliente == null){
                return;
            }

            let param = { activo: true, paginate: false, cliente_id: cliente };
            await apiRegistroPatronal.find(param).then(response => {
                //this.registrosPatronales = response.data;
                let format = response.data;
                if(response.data.length != 0){
                    format.forEach( item => {
                        item.nombreClave = item.nombre + ' - ' + item.clave
                        this.registrosPatronales.push(item);
                    })
                }
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
            
        },

        /**
         * @method cambiaRegistroPatronal Este método realiza la petición a la API de Empresas.
         * @description Este método se almacena en el array empresas=[]. Se ejecuta al seleccionar al menos una empresa.
        */
        async cambiaRegistroPatronal(){
            let param = {   activo: true, 
                            paginate: false, 
                            cliente_id: this.cliente_value,
                            arrayRegistroPatronal: this.registrosPatronalesSeleccionados.length == 0 ? null:JSON.stringify(this.registrosPatronalesSeleccionados)
            };

            if(this.registrosPatronalesSeleccionados.length == 0){
                this.empresas = []
                return
            }
            await empresaApi.getEmpresas(param).then(response => {
                    this.empresas = response.data;
                    this.getEmpleados();
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },
        

        async cambiaEmpresas(items) {
            if(items.length == 0){
                this.sucursales = [];
                return;
            }
            let parametros = { activo: true, paginate: false, arrayEmpresa: JSON.stringify(items)};
            await sucursalApi.getSucursales(parametros).then(response => {
                this.sucursales = response.data;
                this.sucursales.map(suc => suc.nombre += " - "+suc.nombre_empresa);
                this.getEmpleados();
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las sucursales.");
            });
        },

        cambiaSucursales(items) {
            if(items.length == 0){
                this.departamentos = [];
                return;
            }
            
            let parametros = { activo: true, paginate: false, arraySucursal: JSON.stringify(items) };
            departamentoApi.getDepartamentos(parametros).then((response)=>{
                this.departamentos = response.data;
                this.departamentos.map(depto => depto.nombre += " - "+depto.nombre_sucursal);
                this.getEmpleados();
            })
            .catch(error => {
                Notify.ErrorToast(error);
            });
        },

        getPuestos(){

            if(this.empresasSeleccionadas.length == 0 && this.sucursalesSeleccionadas.length == 0 ){
                return
            }

            let parametros = { 
                activo: true, 
                paginate: false, 
                cliente_id: this.cliente_value,
                arrayEmpresa : this.empresasSeleccionadas.length == 0 ? null:JSON.stringify(this.empresasSeleccionadas), 
                arraySucursal : this.sucursalesSeleccionadas.length == 0 ? null:JSON.stringify(this.sucursalesSeleccionadas), 
            };
            apiPuestos.find(parametros).then((response)=>{
                this.puestos = response.data;
                this.puestos.map( puesto =>  puesto.nombre += " - "+ puesto.nombre_sucursal);
                this.getEmpleados();
            })
            .catch(error => {
                Notify.ErrorToast(error);
            });
        },

        resetValues() {

            this.$nextTick(() => { 

                if (this.rol == "root") {
                    this.cliente_value           = null;
                    if(this.$refs.multRegistroPatronal !== undefined)
                        this.$refs.multRegistroPatronal.setData([]);
                    if(this.$refs.multEmpresa !== undefined)
                        this.$refs.multEmpresa.setData([]);
                    if(this.$refs.multSucursal !== undefined)
                        this.$refs.multSucursal.setData([]);
                }
    
                if (this.rol == "admin") {
                    if(this.$refs.multRegistroPatronal !== undefined)
                        this.$refs.multRegistroPatronal.setData([]);
                    if(this.$refs.multEmpresa !== undefined)
                        this.$refs.multEmpresa.setData([]);
                    if(this.$refs.multSucursal !== undefined)
                        this.$refs.multSucursal.setData([]);
                }

                if (this.rol == "admin-empresa") {
                    if(this.$refs.multSucursal !== undefined)
                        this.$refs.multSucursal.setData([]);
                } 

                this.tipoNombre = "Seleccionar tipo";

                if(this.$refs.multDepartamento !== undefined)
                    this.$refs.multDepartamento.setData([]);
                
                if(this.$refs.multPuesto !== undefined)
                    this.$refs.multPuesto.setData([]);

                if(this.$refs.multEmpleado !== undefined)
                    this.$refs.multEmpleado.setData([]);

                
                this.fecha = null
                this.valorIncremento = null;
                

                if(this.$refs.form){
                    this.$refs.form.reset();
                }

                if(this.$refs.formIncremento){
                    this.$refs.formIncremento.reset();
                }

                this.valorSalarioMax = null;
                this.valorSalarioMin = null;
                this.valorNoSalario  = null;


                this.showDatos = false;


            });
        },

        procesar(){
            if(this.empleadosSeleccionados.length == 0){
                this.procesarSinEmpleados();
            }
            else {
                this.procesarIncremento()
            }
        },

        procesarSinEmpleados(){
            this.$refs.formIncremento.validate().then( async success => {
                if(!success) {
                    return;
                }


                if(!this.valorIncremento){
                    Notify.ErrorToast("Agregar un valor a incrementar.");
                    return;
                }

                if(this.fecha == null || this.fecha == ''){
                    Notify.ErrorToast("Seleccionar una fecha.");
                    return;
                }

                let tipo = null;
                this.loadingProcesar = true;

                if(this.tipoIncremento == 'incremento_general_porcentaje'){
                    tipo = 'porcentaje'
                }
                else if(this.tipoIncremento == 'incremento_general_cantidad'){
                    tipo = 'monto'
                }
                else if(this.tipoIncremento == 'salario_general'){
                    tipo = 'fijo'
                }

                let parametros = { 
                    paginate                : false,
                    activo                  : true,
                    usuario_id              : this.datosLogin.id,
                    cliente_id              : this.cliente_value, 
                    rol_name                : this.datosLogin.rol.name,
                    arrayRegistroPatronal   : this.registrosPatronalesSeleccionados.length == 0 ? null:JSON.stringify(this.registrosPatronalesSeleccionados), 
                    arrayEmpresa            : this.empresasSeleccionadas.length == 0 ? null:JSON.stringify(this.empresasSeleccionadas), 
                    arraySucursal           : this.sucursalesSeleccionadas.length == 0 ? null:JSON.stringify(this.sucursalesSeleccionadas), 
                    arrayDepartamento       : this.departamentosSeleccionados.length == 0 ? null:JSON.stringify(this.departamentosSeleccionados),
                    arrayPuesto             : this.puestosSeleccionados.length == 0 ? null:JSON.stringify(this.puestosSeleccionados), 
                    min_salario             : this.valorSalarioMin,
                    max_salario             : this.valorSalarioMax,
                    salario_diferente       : this.valorNoSalario,
                    fecha_de_aplicacion     : this.fecha, 
                    tipo                    : tipo, 
                    valor                   : this.valorIncremento,
                }

                apiIncrementoSalario.consultarSinEmpleados(parametros).then(async (response)=>{
                    let data = response;
                    await data.forEach(item => {
                        item.color = false;
                    })
                    this.datosTabla = data;
                    this.loadingProcesar = false;
                })
                .catch(err => {
                    this.loadingProcesar = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });

                this.showDatos = true;
            });
        },
        
        async procesarIncremento(){
            this.$refs.formIncremento.validate().then( async success => {
                if(!success) {
                    return;
                }

                if(!this.valorIncremento){
                    Notify.ErrorToast("Agregar un valor a incrementar.");
                    return;
                }
                if(this.empleadosSeleccionados.length == 0){
                    Notify.ErrorToast("Seleccionar al menos un empleado.");
                    return;
                }
                
                
                //console.log(this.empleadosSeleccionados);
                let empleados = [];
                let tipo = null;
                this.loadingProcesar = true;

                if(this.tipoIncremento == 'incremento_general_porcentaje'){
                    tipo = 'porcentaje'
                }
                else if(this.tipoIncremento == 'incremento_general_cantidad'){
                    tipo = 'monto'
                }
                else if(this.tipoIncremento == 'salario_general'){
                    tipo = 'fijo'
                }

                
                await this.empleadosSeleccionados.forEach(element => {
                    let campo = {
                        empleado_id:element, 
                        fecha_de_aplicacion: this.fecha, 
                        tipo: tipo, 
                        valor: this.valorIncremento,
                    }
                    empleados.push(campo);
                });

                this.parametrosReporte = empleados;
                
                let parametros = { 
                    empleados: empleados,
                    sistema:'nominas'
                };
                apiIncrementoSalario.consultar(parametros).then(async (response)=>{
                    let data = response;
                    await data.forEach(item => {
                        item.color = false;
                    })
                    this.datosTabla = data;
                    //this.datosTabla = response;
                    this.loadingProcesar = false;
                })
                .catch(err => {
                    this.loadingProcesar = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }

                        Notify.ErrorToast(leyenda);
                    }
                });

                this.showDatos = true;
            });
        },
        abrirModal(item, index){
            this.colaborador.empleado_id = item.empleado_id;
            this.colaborador.nombre_completo = item.nombre_completo;
            this.colaborador.tipo = item.tipo;
            this.colaborador.valor = item.valor;
            this.colaborador.fecha_de_aplicacion = item.fecha_de_aplicacion;
            this.indexColaborador = index;

            this.colaborador.salario_anterior           = item.salario_anterior;
            this.colaborador.nuevo_sbc_fijo             = item.nuevo_sbc_fijo;
            this.colaborador.nuevo_sbc_variable         = item.nuevo_sbc_variable;
            this.colaborador.salario_base_cotizacion    = item.sbc_nuevo;

            this.dialog = true;
        },
        cerrarModal(){
            this.dialog = false;
            this.colaborador            = {
                nombre_completo         : null,
                empleado_id             : null,
                salario_anterior        : null,
                sbc_anterior            : null,
                salario_nuevo           : null,
                sbc_nuevo               : null,
                nombre_completo         : null,
                fecha_de_aplicacion     : null,
                tipo                    : null,
                valor                   : null,
                salario_base_cotizacion : null,
                nuevo_sbc_fijo          : null,
                nuevo_sbc_variable      : null,
            };
            this.indexColaborador = null;
            this.dialogLayout = false;
            this.isSavingLayout = false;
            this.archivo = null;

            if(this.$refs.formLayout){
                this.$refs.formLayout.reset();
            }
        },

        calcularSBC(calculo){ //calculo puede se 'automatico' o 'manual'
            let parametros = { 
                empleados:[
                    {
                        empleado_id         : this.colaborador.empleado_id, 
                        fecha_de_aplicacion : this.colaborador.fecha_de_aplicacion, 
                        tipo                : this.colaborador.tipo, 
                        valor               : this.colaborador.valor,
                        fijo                : this.colaborador.nuevo_sbc_fijo,
                        variable            : this.colaborador.nuevo_sbc_variable,
                        calculo             : calculo
                    }
                ],
                sistema:'nominas'
            };
            this.isLoadingSBC = true;
            this.isSavingActualizar = true;

            apiIncrementoSalario.consultar(parametros).then((response)=>{

                this.colaborador.nuevo_sbc_fijo             = response[0].nuevo_sbc_fijo;
                this.colaborador.salario_base_cotizacion    = response[0].sbc_nuevo;
                this.isLoadingSBC = false;
                this.isSavingActualizar = false;
            })
            .catch(err => {
                this.isLoadingSBC = false;
                this.isSavingActualizar = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });
            
        },

        guardarActualizar(){
            this.isSavingActualizar = true;

            
            this.datosTabla[this.indexColaborador].salario_nuevo        = this.salarioNuevo;
            this.datosTabla[this.indexColaborador].tipo                 = this.colaborador.tipo;
            this.datosTabla[this.indexColaborador].valor                = this.colaborador.valor;
            this.datosTabla[this.indexColaborador].fecha_de_aplicacion  = this.colaborador.fecha_de_aplicacion;
            this.datosTabla[this.indexColaborador].nuevo_sbc_fijo       = this.colaborador.nuevo_sbc_fijo;
            this.datosTabla[this.indexColaborador].nuevo_sbc_variable   = this.colaborador.nuevo_sbc_variable;
            this.datosTabla[this.indexColaborador].sbc_nuevo            = this.colaborador.salario_base_cotizacion;

            this.datosTabla[this.indexColaborador].color = true; 

            this.cerrarModal();
            this.isSavingActualizar = false;
            
        },
        eliminar(item, index){
            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar el registro?",
                "Eliminar",
                () => {
                    this.datosTabla.splice(index,1);
                }
            )
        },

        reporteConsulta(){
            const FileDownload = require("js-file-download");
            var axios = require('axios');
            let parametros = {
                empleados   : this.datosTabla,
                tipo        : 'reporte',
                sistema     : 'nominas'
            }

            let url = 'empleados/guardar-incremento-salarios'
            this.overlay = true;
            let nombre = 'Reporte de incremento de salarios.xlsx';

            let self = this;
            try {
                axios({
                    method: "post",
                    url: url,
                    responseType: "blob",
                    data: parametros,
                }).then(function(response) {
                    self.overlay = false;
                    if (response.status == 200){

                        FileDownload(response.data,nombre);
                    } 
                    else{
                        Notify.ErrorToast("Algo ha salido mal, intenta de nuevo o consulta a soporte técnico.");
                    } 
                })
                .catch(async (error)=>{
                    self.overlay = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }; 
                });
            } catch (error) {
                self.overlay = false;
            }
        },

        async guardarIncremento(){
            if(this.datosTabla.length == 0){
                Notify.ErrorToast("No hay datos para guardar");
                return;
            }



            const FileDownload = require("js-file-download");
            var axios = require('axios');
            let parametros = {
                empleados   : this.datosTabla,
                tipo        : 'guardar',
                sistema     : 'nominas'
            }

            let url = 'empleados/guardar-incremento-salarios'
            this.isSaving = true;
            let nombre = 'Reporte de incremento de salarios.xlsx';

            let self = this;
            try {
                axios({
                    method: "post",
                    url: url,
                    responseType: "blob",
                    data: parametros,
                }).then(function(response) {
                    self.isSaving = false;
                    self.datosTabla = [];
                    if (response.status == 200){

                        FileDownload(response.data,nombre);
                    } 
                    else{
                        Notify.ErrorToast("Algo ha salido mal, intenta de nuevo o consulta a soporte técnico.");
                    } 
                })
                .catch(async (error)=>{
                    self.isSaving = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }; 
                });
            } catch (error) {
                self.isSaving = false;
            }


        },

        changeItem(data){
            this.tipoNombre    = data.nombre;
            this.tipoIncremento = data.valor;
        },

        dateFormat(fecha, tipo = 'tabla') {
            if(fecha == null){
                return;
            }
            let fecha2 = fecha.split("-");
            if(tipo == 'tabla'){
                return fecha2[2] + " / " + fecha2[1]+ " / " + fecha2[0];
            }
            else{
                return fecha2[2] + "/" + fecha2[1]+ "/" + fecha2[0];
            }
            
        },

        formatNum(num){
            if(typeof num === 'string'){
                num = num.replace(',','')
            }

            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(num);
        },

        presicionNumero(event, valor){
            if (/\.\d{3}/.test(valor)) 
                return event.preventDefault();
        },

        /**
         * @method validarFecha Este método valida una fecha
         * @description Valida la fecha que se pasa y devuelve true en caso de que la fecha exista devuelve verdadero (true) y 
         * en caso de que la fecha no exista o el formato sea incorrecto devuelve falso (false).
         * @param {string} valorFecha formato AAAA-MM-DD
         * @return {boolean} devuelve un true o false.
        */
        validarFecha(valorFecha) {
            try{
                let fecha = valorFecha.split("-");        
                let dia = fecha[2];        
                let mes = fecha[1];        
                let ano = fecha[0];        
                let estado = true;  
                let dmax = 0;
                let diaC = null;
                let mesC =  null;
                let anoC = null;
                
                if ((dia.length == 2) && (mes.length == 2) && (ano.length == 4)) {        
                    switch (parseInt(mes)) {        
                        case 1:dmax = 31;break;        
                        case 2: 
                            if (((ano % 4 == 0) && (ano % 100 != 0 )) || (ano % 400 == 0)) //validación para año biciesto
                                dmax = 29; 
                            else 
                                dmax = 28;        
                            break;        
                        case 3:dmax = 31;break;        
                        case 4:dmax = 30;break;        
                        case 5:dmax = 31;break;        
                        case 6:dmax = 30;break;        
                        case 7:dmax = 31;break;        
                        case 8:dmax = 31;break;        
                        case 9:dmax = 30;break;        
                        case 10:dmax = 31;break;       
                        case 11:dmax = 30;break;      
                        case 12:dmax = 31;break;       
                    }  
                    
                    dmax!=""?dmax:dmax=-1;
                    
                    if ((dia >= 1) && (dia <= dmax) && (mes >= 1) && (mes <= 12)) {
                        let i=0;
                        for (i=0 ; i<fecha[2].length; i++) {      
                            diaC = fecha[2].charAt(i).charCodeAt(0);        
                            (!((diaC > 47) && (diaC < 58)))?estado = false:'';       
                            mesC = fecha[1].charAt(i).charCodeAt(0);        
                            (!((mesC > 47) && (mesC < 58)))?estado = false:'';       
                        }  
                    } 
                    else{
                        estado = false;
                    }
                    
                    let j = 0;
                    for(j=0; j < fecha[0].length; j++) {
                        anoC = fecha[0].charAt(j).charCodeAt(0);  
                        (!((anoC > 47) && (anoC < 58)))?estado = false:'';        
                    }
                } 
                else {
                    estado = false;
                }        
                return estado; 
            }catch(err){
                return false;
            }  
        },

        descargarLayout(){

            if(this.cliente_value == null){
                Notify.ErrorToast("Seleccionar un cliente.");
                return
            }

            let parametros = { 
                paginate                : false,
                activo                  : true,
                usuario_id              : this.datosLogin.id,
                cliente_id              : this.cliente_value, 
                rol_name                : this.datosLogin.rol.name,
                arrayRegistroPatronal   : this.registrosPatronalesSeleccionados.length == 0 ? null:JSON.stringify(this.registrosPatronalesSeleccionados), 
                arrayEmpresa            : this.empresasSeleccionadas.length == 0 ? null:JSON.stringify(this.empresasSeleccionadas), 
                arraySucursal           : this.sucursalesSeleccionadas.length == 0 ? null:JSON.stringify(this.sucursalesSeleccionadas), 
                arrayDepartamento       : this.departamentosSeleccionados.length == 0 ? null:JSON.stringify(this.departamentosSeleccionados),
                arrayPuesto             : this.puestosSeleccionados.length == 0 ? null:JSON.stringify(this.puestosSeleccionados), 
                min_salario             : this.valorSalarioMin,
                max_salario             : this.valorSalarioMax,
                salario_diferente       : this.valorNoSalario
            }

            this.loadingLottie = true;
            this.fraseLoading = 'Descargando';
            const FileDownload = require("js-file-download");
            let nombre;
            nombre = "Incremento_salarial.xlsx";
            let url = "empleados/exportar-incremento";
            try {
                Vue.axios({
                    method: "GET",
                    responseType: "blob",
                    url: url,
                    params: parametros,
                }).then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    this.loadingLottie = false;
                })
                .catch(async (error)=>{
                    this.loadingLottie = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }; 
                });
            } 
            catch (err) {
                Notify.ErrorToast("No se pudo realizar la petición");
                console.log(err);
                this.loadingLottie = false;
            }
        },
        abrirModalLayout(){
            this.dialogLayout = true;
        },
        cerrarModalLayout(){

            this.dialogLayout = false;
            this.isSavingLayout = false;
            this.archivo = null;

            if(this.$refs.formLayout){
                this.$refs.formLayout.reset();
            }
            
        },
        importarLayout(){

            let formData = new FormData();
            formData.append("archivo", this.archivo);
            formData.append("cliente_id", this.cliente_value);
            formData.append("usuario_id", this.datosLogin.id);
            let url = "empleados/importar-incremento";
            
            this.loadingLottie = true;
            Vue.axios({
                method: "POST",
                url: url,
                data: formData,
                config: { headers: { "Content-Type": "multipart/form-data" } }
            })
            .then(async (response) => {

                let data = response.data;
                await data.forEach(item => {
                    item.color = false;
                })
                this.datosTabla = data;

                this.loadingLottie = false;
                this.cerrarModalLayout();
            })
            .catch(err => {
                Notify.ErrorToast("Error al subir archivo.");
                this.cerrarModalLayout();
                this.loadingLottie = false;
            });
        },


        /**
         * @method redireccionar Método que redirecciona a una vista
         * @description Método que redirecciona a la vista dashboard si el no es root, admin, admin-empresa, admin-sucursal
         */
        redireccionar(){
            this.$router.push('/dashboard');
        },

    },
    //CREATED Se ejecutan las funciones o intrucciones iniciales para el funcionamiento del modulo.
    created() {
        this.init();
    },
    
}
</script>


<style scoped>
    .titleModal {
        font-family: "Montserrat";
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 18px !important;
        line-height: 22px !important;
        color: #1E2245;
    }
    .btnModulo.theme--light.v-btn {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #828282;
        text-transform: none;
        transition: 0.3s;
        height: 38px !important;
        background: #EFEFEF;
        border-radius: 8px;
    }

    .btnModulo.theme--light.v-btn:hover {
        background: #2DB9D1 !important;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important;
        -webkit-box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08) !important; 
        -moz-box-shadow:  0px 4px 12px rgba(0, 0, 0, 0.08) !important;
    }
    .btnTipoIncremento.theme--light.v-btn {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        color: #828282;
        text-transform: none;
        background: #EFEFEF;
        border-radius: 8px;
    }
    .margen {
        height: 48px;
        margin-bottom: 20px;
        margin-left: 1px;
    }
    .text-exportar{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        letter-spacing: 0.1px;
        color: #828282;
    }
    .btnGeneral {
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 13px !important;
        line-height: 18px !important;
        background-color: #1E2245 !important;
        border-radius: 30px !important;
        color: white !important;
        height: 40px !important;
        outline: none;
        text-transform: none !important;
        margin-left: 3%;
        transition: all .2s;
    }

    .areaConsulta{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 37px;
        color: #828282;
    }

    .informacionGeneralConsulta{
        border: 1px solid transparent;
        box-sizing: border-box;
        border-radius: 15px;
    }



    th{
        color:#828282;
        font-family: 'Montserrat';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        padding: 8px;
        text-align: left;
    }

    .tbl-incidencias tbody{
        background:#FFFFFF;
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
        margin-bottom: 10em;
    }

    .tbl-incidencias tbody td{
        color: #1E2144;
        font-family: 'Montserrat';
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        padding: 2px;
        text-align: left;
    }

    .tbl-incidencias {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .tbl-incidencias .tbl-header {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
    }

    .tbl-incidencias .tbl-header {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
    }
    .firstth {
        border-top-left-radius: 20px !important;
    }
    .lastth {
        border-top-right-radius: 20px !important;
    }
    .emptyTableComplementos{
        color: #c5c5c5 !important;
        background:#FFFFFF;
        font-family: "Montserrat" !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 22px !important;
        padding-bottom: 50px !important;
        padding-top: 50px !important;
        text-align: center !important;
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
    }
    .thCheck{
        min-width: 56px;
    }
    .thTitulos{
        border-width:1px; 
        border-color:#E0E0E0 ;
    }
    .tdBlanco{
        color: #c5c5c5 !important;
        background:#FFFFFF;
        font-family: "Montserrat" !important;
        font-size: 18px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 22px !important;
        padding-bottom: 20px !important;
        padding-top: 20px !important;
        text-align: center !important;
        border-bottom-left-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
    }
    .trBody{
        border-width:1px; 
        border-color:#E0E0E0 ;
        border-bottom-style: solid;
        margin-top: 10px!important;
    }

    .exportar-en{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #828282;
    }
    .custom-row-true{
        background: #FFCDD2;
    }
    .custom-row-false{
        background: none;
    }

    .v-btn-hover:hover:before {
      background-color: transparent;
      
    }
    .v-btn-hover:before {
        display: none;
    }
    .custom-border{
        border-width:1px; 
        border-color:#E0E0E0 ;
        border-right-style: solid;
    }
    .informacionGeneralTitulo{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #1E2144;
    }

    .menuClassAcciones{
        /* box-shadow: none; */
        border-radius: 10px!important;
        margin-top:15px;
    }
    .btnAcciones {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background:#58C5D8 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
    }
    .btnAcciones:hover {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background:#58C5D8 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
        box-shadow: 0px 5px 8px rgba(26, 30, 66, 0.2);
        -webkit-transform: translatey(-1px);
        transform: translatey(-1px);
    }
    .btnAccionesContenedor {
        width: 300px;
        height: auto;
        background-color: #FFF;
    }

    div.btnAccionesTitulo {
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesTitulo span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
        color: #1E2245;
    }
    div.btnAccionesItem {
        width: 100%;
        cursor: pointer;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesItem span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: #828282;
    }
    div.btnAccionesItem:hover {
        background: #F6F6F8;
    }
</style>
